<template>
  <div>
    <el-form class="form" ref="form" label-width="100px">
      <el-form-item label="身份证号码">
        <el-input v-model="cardid"></el-input>
      </el-form-item>

      <el-form-item label="上传照片">
        <UploadImg ref="durationUpload" :buttonTitle="`上传照片`" @uploadSuccess="uploadSuccess"></UploadImg>
      </el-form-item>

      <el-form-item label="开始时间">
        <el-input v-model="startTime"></el-input>
      </el-form-item>

      <el-form-item label="刷科1 科4  (全刷:0 科1:1  科4:4)">
        <el-input v-model="subjectType"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button :loading="isLoading" type="primary" @click="onSubmit">提交从业学时</el-button>
        <el-button @click="clear">清除</el-button>
      </el-form-item>
    </el-form>

    <el-form class="form" ref="form" label-width="100px">
      <el-form-item label="身份证号码">
        <el-input v-model="loginCardId"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmitC1Login">小车登录</el-button>
        <el-button type="primary" @click="onSubmitB1Login">大车登录</el-button>
        <el-button type="primary" @click="onSubmitSearch">查用户信息</el-button>
      </el-form-item>

      <el-form-item label="结果">
        <span>{{ result }}</span>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import UploadImg from '@/components/system/uploadImg.vue'
import { cyBackendUpload, c1Login, b1Login, studentInfo } from '@/api/cyStudent'
export default {
  components: {
    UploadImg
  },
  data () {
    return {
      subjectType: '0',
      cardid: '',
      imgList: [],
      isLoading: false,
      startTime: '',
      isC1LoginLoading: false,
      isB1LoginLoading: false,
      result: '',
      loginCardId: ''
    }
  },
  methods: {
    async onSubmitSearch () {
      const res = await studentInfo({
        cardid: this.loginCardId,
        password: '123456'
      })
      this.result = res
    },
    async onSubmitB1Login () {
      const res = await b1Login({
        cardid: this.loginCardId,
        password: '123456'
      })
      this.result = res
    },
    async onSubmitC1Login () {
      const res = await c1Login({
        cardid: this.loginCardId,
        password: '123456'
      })
      this.result = res
    },
    async onSubmit () {

      // console.log(this.imgList)
      // return
      if (this.imgList.length < 17) {
        this.$message("照片数不足差" + (17 - this.imgList.length) + "张")
        return
      }
      if (this.cardid.length < 4) {
        this.$message("请输入正确的身份证号码")
        return
      }
      this.isLoading = true
      try {
        const res = await cyBackendUpload({
          startTime: this.startTime,
          cardid: this.cardid,
          imgs: this.imgList.join(','),
          subjectType: this.subjectType
        })
        if (res.data) {
          this.$message('提交成功')
        }
        this.isLoading = false
        this.clear()
      } catch (error) {
        this.isLoading = false
      }

    },
    clear () {
      this.startTime = ""
      this.cardid = ""
      this.imgList = []
      this.$refs.durationUpload.clear()
    },
    uploadSuccess (val) {
      for (const item of val) {
        this.imgList.push(item.fileUrl)
      }
    },
  }
}

</script>
<style lang="less" scoped>
.form {
  padding: 20px;
  width: 500px;
}
</style>
