import { phpPost, post } from "./base";

export async function cyBackendUpload(params) {
  try {
    const res = await post("studentCy/cyBackendUpload", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function c1Login(params) {
  try {
    const res = await phpPost("app/login/index", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function b1Login(params) {
  try {
    const res = await post("student/loginA2B2", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function studentInfo(params) {
  try {
    const res = await post("student/info", params);
    return res;
  } catch (error) {
    throw error;
  }
}
